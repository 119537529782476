<template>
  <el-container class="shopList">
    <el-header class="box add">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>个人中心</el-breadcrumb-item>
        <el-breadcrumb-item>权利移交</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-main class="p15 pt15">
      <div style="padding-bottom: 20px">
        <el-alert
          title="您正在进行敏感操作，继续操作请验证您之前的身份"
          type="warning"
          show-icon
          description="权利移交后，您将不再拥有目前的用户角色操作权限"
          :closable="false"
          effect="dark"
        >
        </el-alert>
      </div>
      <!-- <el-col :span="24" class="tl">
            <div class="p10 error_text f13 mb20">
              <p>您正在进行敏感操作，继续操作请验证您之前的身份</p>
              <p>权利移交后，您将不再拥有目前的用户角色操作权限</p>
            </div>
          </el-col> -->
      <div
        style="
          background-color: #fff;
          padding: 20px 20px 0;
          border-radius: 4px;
          overflow: hidden;
        "
      >
        <el-form
          style="width: 30%"
          status-icon
          ref="form3"
          :model="form3"
          class="demo-ruleForm"
          label-width="180px"
        >
          <el-form-item label="移交账号" class="w">
            <el-input
              placeholder="移交账号"
              maxlength="30"
              disabled
              clearable
              v-model="user_loginname"
              class="input-with-select"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="原密码" class="w">
            <el-input
              type="text"
              placeholder="原密码"
              minlength="4"
              maxlength="15"
              clearable
              v-model="loginpass"
              class="input-with-select"
              @input="handelKeyup(loginpass)"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label="移交接收账号"
            class="w"
            prop="user_id"
            :rules="[
              {
                required: true,
                message: '请输入移交接收账号',
                trigger: 'blur',
              },
              {
                pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+$/,
                message: '不允许输入空格等特殊符号',
              },
              { maxlength: 50, message: '最多输入50个字符' },
            ]"
          >
            <div class="df">
              <el-select
                :disabled="!showDiv"
                v-model="form3.user_id"
                filterable
                placeholder="请选择移交接收账号"
              >
                <el-option
                  v-for="item in Options"
                  :key="item.user_id"
                  :label="item.user_mobilephone"
                  :value="String(item.user_id)"
                >
                  <span style="float: left">{{ item.user_mobilephone }}</span>
                  <span style="float: right">{{ item.user_name }}</span>
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="df">
              <el-button
                v-if="showDiv"
                size="medium"
                type="primary"
                @click="submitForm2('form3')"
                >提交</el-button
              >
              <el-button v-else size="medium" type="primary" @click="submitForm"
                >验证</el-button
              >
            </div>
          </el-form-item>

          <!-- <el-row class="box f14">
            <el-col :span="11" class="tl clear">
              <el-form-item
                label="移交账号："
                size="medium"
                class="w"
                label-width="28%"
              >
                <el-input
                  placeholder="移交账号"
                  maxlength="30"
                  disabled
                  clearable
                  v-model="user_loginname"
                  class="input-with-select"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11" class="clear">
              <el-form-item
                label="原密码："
                size="medium"
                class="w"
                label-width="28%"
              >
                <el-input
                  type="text"
                  placeholder="原密码"
                  minlength="4"
                  maxlength="15"
                  clearable
                  v-model="loginpass"
                  class="input-with-select"
                  @input="handelKeyup(loginpass)"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11" class="tl clear">
              <el-form-item
                label="移交接收账号："
                size="medium"
                class="w"
                label-width="28%"
                prop="user_id"
                :rules="[
                  {
                    required: true,
                    message: '请输入移交接收账号',
                    trigger: 'blur',
                  },
                  {
                    pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+$/,
                    message: '不允许输入空格等特殊符号',
                  },
                  { maxlength: 50, message: '最多输入50个字符' },
                ]"
              >
                <el-select
                  :disabled="!showDiv"
                  v-model="form3.user_id"
                  filterable
                  placeholder="请选择移交接收账号"
                >
                  <el-option
                    v-for="item in Options"
                    :key="item.user_id"
                    :label="item.user_mobilephone"
                    :value="String(item.user_id)"
                  >
                    <span style="float: left">{{ item.user_mobilephone }}</span>
                    <span style="float: right">{{ item.user_name }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <div v-if="showDiv">
              <el-col :span="22" class="tl">
                <el-button
                  class="ml_5"
                  size="medium"
                  type="primary"
                  @click="submitForm2('form3')"
                  >提交</el-button
                >
              </el-col>
            </div>
            <el-col v-else :span="22" class="tl">
              <el-button
                class="ml_5"
                size="medium"
                type="primary"
                @click="submitForm"
                >验证</el-button
              >
            </el-col>
          </el-row> -->
        </el-form>
      </div>
    </el-main>
  </el-container>
</template>
<script>
import axios from "../../../util/api";
import { local, aa, objKeySort } from "../../../util/util";
import { imageUrl } from "../../../config/index";
// import JcRange from "@/components/JcRange.vue";
import { mapState } from "vuex";
import md5 from "js-md5";
export default {
  components: {
    // JcRange
  },
  data() {
    var checkStatus = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请拖动滑块完成验证"));
      } else {
        callback();
      }
    };
    return {
      user: "",
      timer: "",
      loading: false,
      tableData: [],
      Options: [],
      show: true,
      showDiv: false,
      count: "",
      user_loginname: "",
      user_loginpass: "",
      loginpass: "",
      form3: {
        // mess_code:'',
        // query_param:'',
        user_id: "",
      },
      page: {
        pager_offset: "0",
        pager_openset: "1000",
        user_type: "4",
        user_name: "",
        user_state: "",
        query_param: "",
      },
      status1: "",
      verify: false,
      ruleForm: {
        status: [{ validator: checkStatus, trigger: "change" }],
      },
      isMenu: "",
      company_name: "",
      url: "",
    };
  },
  watch: {},
  created() {
    var that = this;
    that.user = local.get("user");
    that.getData();
  },
  methods: {
    init(params) {
      axios.get("/pc/user/list", params).then((v) => {
        v.data.user_list.splice(
          v.data.user_list.findIndex(
            (item) => item.user_mobilephone === this.user_loginname
          ),
          1
        );
        this.Options = v.data.user_list;
      });
    },
    handelKeyup(val) {
      this.user_loginpass += val.substr(val.length - 1, 1);
      this.loginpass = val.replace(/./g, "●");
    },
    onMpanelSuccess() {
      this.form3.query_param = "2fb1c5cf58867b5bbc9a1b145a86f3a0";
    },
    onMpanelError() {
      this.form3.query_param = "";
    },
    getData() {
      var that = this;
      axios.get("/pc/user/single").then((v) => {
        that.user = v.data.user;
        that.user_loginname = that.user.user_mobilephone;
      });
    },
    submitForm() {
      if (this.user_loginname == "") {
        this.$message.error("请输入登录账号");
        return false;
      }
      if (this.user_loginpass == "") {
        this.$message.error("请输入密码");
        return false;
      }
      this.checkPassword(this.user_loginpass);
    },
    submitForm2(form3) {
      this.$confirm("确定移交权限?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          this.$refs[form3].validate((valid) => {
            if (valid) {
              axios.put("/pc/user/power/switch", this.form3).then((v) => {
                this.login();
              });
            } else {
              this.$message.error("请检查表单必填项");
              return false;
            }
          });
        })
        .catch(() => {});
    },
    login() {
      axios
        .post("/pc/user/login", {
          user_loginname: this.user_loginname,
          user_loginpass: md5(this.user_loginpass),
        })
        .then((v) => {
          local.set("token", v.data.token);
          local.set("user_loginname", this.user_loginname);
          axios.get("/pc/user/single").then((response) => {
            this.user = response.data.user;
            local.set("user", response.data.user);
            local.set("company_id", response.data.user.last_login_company_id);
            if (response.data.user.user_type == 3) {
              if (v.data.complete_state == "234") {
                this.isMenu = 1;
                local.set("isMenu", 1);
                this.$router.push({ name: "Public" });
              } else if (v.data.complete_state == "235") {
                this.isMenu = 2;
                local.set("isMenu", 2);
                this.getCompany(
                  String(response.data.user.last_login_company_id)
                );
                this.getMenu(response.data.user.last_login_company_id);
              }
            } else if (response.data.user.user_type == 4) {
              this.isMenu = 2;
              local.set("isMenu", 2);
              this.getCompany1(
                String(response.data.user.last_login_company_id)
              );
              this.getMenu(response.data.user.last_login_company_id);
            } else {
              this.isMenu = 2;
              local.set("isMenu", 2);
              this.getMenu(response.data.user.last_login_company_id);
            }
          });
        })
        .catch((v) => {
          this.$message.error("网络异常");
        });
    },
    //获取菜单
    getMenu(params) {
      var that = this;
      axios.get("/pc/user-role/menu").then((v) => {
        that.url = v.data.menu_list[0].menu_list[0].menu_url;
        that.$router.replace({
          path: that.url + "?company_id=" + params,
        });
      });
    },
    //获取企业信息
    getCompany(params) {
      axios.get("/pc/company/list", { pager_openset: "50" }).then((v) => {
        var obj = {};
        obj = v.data.company_list.find(function (item) {
          return item.company_id == params;
        });
        this.company_name = obj.company_name;
        local.set("company_name", obj.company_name);
      });
    },
    //普通职员获取企业信息company/single
    getCompany1(params) {
      axios.get("/pc/company/single", { company_id: params }).then((v) => {
        this.company_name = v.data.company.company_name;
        local.set("company_name", v.data.company.company_name);
      });
    },
    //验证账号密码是否有效
    checkPassword(val) {
      axios
        .get("/pc/user/verify", {
          user_loginname: this.user_loginname,
          user_loginpass: md5(val),
        })
        .then((v) => {
          if (v.code != 200) {
            this.user_loginpass = "";
          } else {
            this.showDiv = true;
            this.init(this.page);
          }
          // this.verify=true
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.shopList {
  overflow-x: hidden;
  .add {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .active {
    background: #2ec68a;
    border-color: #2ec68a;
    color: #ffffff;
  }
  .back_btn {
    background: rgba(247, 248, 249, 1);
    border: 1px solid rgba(217, 222, 225, 1);
  }
}
</style>
