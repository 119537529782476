var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-container',{staticClass:"shopList"},[_c('el-header',{staticClass:"box add"},[_c('el-breadcrumb',{attrs:{"separator-class":"el-icon-arrow-right"}},[_c('el-breadcrumb-item',[_vm._v("个人中心")]),_c('el-breadcrumb-item',[_vm._v("权利移交")])],1)],1),_c('el-main',{staticClass:"p15 pt15"},[_c('div',{staticStyle:{"padding-bottom":"20px"}},[_c('el-alert',{attrs:{"title":"您正在进行敏感操作，继续操作请验证您之前的身份","type":"warning","show-icon":"","description":"权利移交后，您将不再拥有目前的用户角色操作权限","closable":false,"effect":"dark"}})],1),_c('div',{staticStyle:{"background-color":"#fff","padding":"20px 20px 0","border-radius":"4px","overflow":"hidden"}},[_c('el-form',{ref:"form3",staticClass:"demo-ruleForm",staticStyle:{"width":"30%"},attrs:{"status-icon":"","model":_vm.form3,"label-width":"180px"}},[_c('el-form-item',{staticClass:"w",attrs:{"label":"移交账号"}},[_c('el-input',{staticClass:"input-with-select",attrs:{"placeholder":"移交账号","maxlength":"30","disabled":"","clearable":""},model:{value:(_vm.user_loginname),callback:function ($$v) {_vm.user_loginname=$$v},expression:"user_loginname"}})],1),_c('el-form-item',{staticClass:"w",attrs:{"label":"原密码"}},[_c('el-input',{staticClass:"input-with-select",attrs:{"type":"text","placeholder":"原密码","minlength":"4","maxlength":"15","clearable":""},on:{"input":function($event){return _vm.handelKeyup(_vm.loginpass)}},model:{value:(_vm.loginpass),callback:function ($$v) {_vm.loginpass=$$v},expression:"loginpass"}})],1),_c('el-form-item',{staticClass:"w",attrs:{"label":"移交接收账号","prop":"user_id","rules":[
            {
              required: true,
              message: '请输入移交接收账号',
              trigger: 'blur',
            },
            {
              pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+$/,
              message: '不允许输入空格等特殊符号',
            },
            { maxlength: 50, message: '最多输入50个字符' } ]}},[_c('div',{staticClass:"df"},[_c('el-select',{attrs:{"disabled":!_vm.showDiv,"filterable":"","placeholder":"请选择移交接收账号"},model:{value:(_vm.form3.user_id),callback:function ($$v) {_vm.$set(_vm.form3, "user_id", $$v)},expression:"form3.user_id"}},_vm._l((_vm.Options),function(item){return _c('el-option',{key:item.user_id,attrs:{"label":item.user_mobilephone,"value":String(item.user_id)}},[_c('span',{staticStyle:{"float":"left"}},[_vm._v(_vm._s(item.user_mobilephone))]),_c('span',{staticStyle:{"float":"right"}},[_vm._v(_vm._s(item.user_name))])])}),1)],1)]),_c('el-form-item',[_c('div',{staticClass:"df"},[(_vm.showDiv)?_c('el-button',{attrs:{"size":"medium","type":"primary"},on:{"click":function($event){return _vm.submitForm2('form3')}}},[_vm._v("提交")]):_c('el-button',{attrs:{"size":"medium","type":"primary"},on:{"click":_vm.submitForm}},[_vm._v("验证")])],1)])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }